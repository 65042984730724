import React from "react"
import { graphql, Link } from "gatsby"
import SEO from "../components/seo"
import { BlogPostDetailsWrapper, BlogDetailsContent } from "./templates.style"
import PostDetails from "../components/PostDetails/postDetails"
// amp page
export default (props: any) => {
  const post = props.data.markdownRemark
  const slug = post.fields.slug
  return (
    <>
      <SEO
        isPost
        title={post.frontmatter.schema.title}
        description={post.frontmatter.schema.description}
        pathname={slug}
        firstPublicationDate={post.frontmatter.firstPublicationDate}
        keywords={post.frontmatter.keywords}
      />
      <BlogPostDetailsWrapper>
        <BlogDetailsContent>
          <amp-img
            src={post.frontmatter.cover.childImageSharp.fluid.src}
            width="600"
            height="450"
            layout="responsive"
            alt={post.frontmatter.h1}
          />
          <PostDetails
            title={post.frontmatter.h1}
            date={post.frontmatter.date}
            preview={null}
            description={post.html}
            youtubeLink={post.frontmatter.youtubeLink}
          />
        </BlogDetailsContent>
      </BlogPostDetailsWrapper>
    </>
  )
}

export const pageQuery = graphql`
  query BlogPostBySlugAmp($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      fields {
        slug
      }
      frontmatter {
        seo {
          title
          description
        }
        schema {
          title
          description
        }
        keywords
        date(formatString: "DD MMM, YYYY", locale: "ru")
        firstPublicationDate: date
        h1
        tags
        cover {
          childImageSharp {
            fluid(maxWidth: 1170, quality: 100) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    }
  }
`
